.star {
  width: 60px;
  height: 60px;
}

.rating {
  outline: 0;
  justify-content: inherit;
  margin: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.m-blockui {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  display: table;
  table-layout: fixed; }
  .m-blockui > span {
    display: table-cell;
    vertical-align: middle;
    padding: 8px 15px;
    font-size: 1rem;
    font-weight: 400; }
    .m-blockui > span > .m-loader,
    .m-blockui > span > .m-spinner {
      margin-right: 10px; }

.m-blockui {
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  -moz-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2); }
  .m-blockui.m-blockui-no-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .m-blockui > span {
    color: #6f727d; }

.m-blockui.m-blockui--skin-dark {
  background: #2c2e3e;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.4);
  -moz-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.4);
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.4); }
  .m-blockui.m-blockui--skin-dark.m-blockui-no-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .m-blockui.m-blockui--skin-dark > span {
    color: #e6e6e6; }

.m-page-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: none; }
  .m-page--loading .m-page-loader {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex; }
  .m-page-loader.m-page-loader--non-block {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    width: auto; }
    .m-page--loading-non-block .m-page-loader.m-page-loader--non-block {
      display: inline-block; }

  .m-page-loader.m-page-loader--relative {
    position: relative;
  }

  .m-page-loader.m-page-loader--absolute {
    position: absolute;
    margin-top: -5px;
  }

.m-page-loader {
  background: #ffffff; }
  .m-page-loader.m-page-loader--non-block {
    background: transparent; }

.m-page-loader.m-page-loader--skin-dark {
  background: #2c2e3e; }
  .m-page-loader.m-page-loader--skin-dark.m-page-loader--non-block {
    background: transparent; }

.w-100 {
  width: 100% !important;
}

.m-login.m-login--2 .m-login__wrapper {
  align-items: center;
  display: flex;
  padding: 0;
}

.ht-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ht-modal__bg {
  background-color: rgba(0,0,0, .66);
  position: absolute;
  width: 100%;
  height: 100%;
}

.ht-modal__body {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 20px 20px 0px 20px;
  border-radius: 3px;
  max-width: 320px;
  width: 100%;
}
